import { API } from "../config";
import axios from 'axios';
// create course
export const courseAdd = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/createCourse`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
// course list
export const allCourse = async () => {
    try {
        const response = await axios.get(`${API}/courseList`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
// single course
export const singleCourse = async (id) => {
    try {
        const response = await axios.get(`${API}/singleCourse/${id}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
// create course
export const courseUpdate = async (id,body = {}) => {
    try {
        const response = await axios.put(`${API}/updateCourse/${id}`, body,{headers: {
            'Content-Type': 'multipart/form-data',
          },});
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
// delete course
export const courseDelete = async (id) => {
    try {
        const response = await axios.delete(`${API}/deleteCourse/${id}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
// search
export const searchTerm = async (id) => {
    try {
        const response = await axios.get(`${API}/courseSearch/${id}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
// course discount add
export const discountAdd = async (id,discount,refer,paymentDiscount) => {
    try {
        const response = await axios.put(`${API}/discountAdd/${id}`,{
            discount: discount,
            refer: refer,
            paymentDiscount: paymentDiscount
          });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// delete branch
export const branchDelete = async (id) => {
    try {
        const response = await axios.delete(`${API}/deleteBranch/${id}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
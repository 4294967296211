import React, { useEffect, useRef, useState } from 'react'
import { allOrder, deleteOrders, studentPayment, updateOrderStatus } from '../../../api/order'
import { Col, Container, Row, Table, Form } from 'react-bootstrap'
import { API } from "../../../config";
import AdminHeader from '../../../Component/AdminHeader/AdminHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import "./OrderList.css";
import { deleteOrder } from './../../../api/order';
import { updateCertificate, updatePdf } from '../../../api/admin';
import { useForm } from 'react-hook-form';

const OrderList = () => {
    const [orderList, setOrderList] = useState([]);
    const [count, setCount] = useState(0);
    const [certificate, setPdfFile] = useState("");
    const [userId, setUserId] = useState("");
    const [paymentValue, setPaymentValue] = useState("");
    const form = useRef(null);
    const { handleSubmit, register, setValue } = useForm();
    const [imageKey, setImageKey] = useState("");
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setPdfFile(selectedFile);
    };
    useEffect(() => {
        allOrder().then((data) => {
            if (data.statusCode !== 200) {

            }
            else {
                setOrderList(data.data);
            }
        })
    }, [count]);

    const activeOrder = (orderInfo) => {
        let activeOrder = {
            "complete": true,
            "refer_id": orderInfo.refer_id,
            "refer_name": orderInfo.refer_name,
            "amount": orderInfo.refer_discount,
            "total": 1,
        }
        updateOrderStatus(orderInfo.id, activeOrder).then((data) => {
            setCount(count + 1);
        });
    }
    const disableOrder = (orderId) => {
        let complete = {
            name: false
        }
        updateOrderStatus(orderId, complete).then((data) => {
            setCount(count + 1);
        });
    }

    const deleteOrder = (orderId) => {
        deleteOrders(orderId).then((data) => {
            setUserId(count + 1);
        });
    }

    const certificateAdd = (userId) => {
        setUserId(userId);
    }

    function handleChange(e) {
        setImageKey(e.target.files[0])
    }

    const pdfDownload = (userId) => {
        let course = {
            "certificate_image": imageKey
        }
        updatePdf(userId, course).then((data) => {
            setCount(count + 1);
        });
    }

    const downloadFile = async (certificate) => {
        const imageUrl = `${API}/${certificate}`; // Replace with the actual image URL

        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Create an anchor element with a download attribute
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = 'niomit.jpg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const paymentTypeUpdate = (userId, paymentType) => {
        let paymentOption={
            "paymentType": paymentType
        }
        studentPayment(userId, paymentOption).then((data) => {
            setCount(count + 1);
        });

    }

    return (
        <>
            <AdminHeader />
            <section className='orderSection'>
                <Container>
                    <Row>
                        <Col md={12} className='orderHeading'>
                            <h1>Student list</h1>
                        </Col>
                        <Col md={12} className='orderTable'>
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Number</th>
                                        <th>Course</th>
                                        <th>Branch</th>
                                        <th>Refer</th>
                                        <th>Transaction id</th>
                                        <th>Payment</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderList.map((data, index) => <tr key={`order` + index} className={data.paymentType === 'half' ? 'halfPayment' : ''}>
                                        <td>{orderList.length - (index)}</td>
                                        <td><img src={`${API}/${data.image}`} alt={data && data.course} width="50px" height="40px" /></td>
                                        <td>{data.user_name}</td>
                                        <td>{data.mobile}</td>
                                        <td>{data.course}</td>
                                        <td>{data.branch}</td>
                                        <td>{data.refer_name}</td>
                                        <td>{data.transaction}{data.paymentType}</td>
                                        <td>
                                            <Form.Select aria-label="Default select example" style={{width: "120px"}} value={data.paymentType}  onChange={(e) => paymentTypeUpdate(data.id, e.target.value)}>
                                                <option value="half">Half Payment</option>
                                                <option value="full">Full Payment</option>
                                            </Form.Select>
                                        </td>
                                        <td className='actionBtn'>
                                            {data.complete === "0" ?
                                                <div>
                                                    <button onClick={() => activeOrder(data)} className="btn active"><FontAwesomeIcon icon={faCheck} /></button>
                                                    <button onClick={() => deleteOrder(data.id)} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
                                                </div>
                                                : <div className='d-flex pdfUpload'>
                                                    <input type='file' onChange={handleChange} />
                                                    <button className='btn btn-info pdfBtn' type='submit' onClick={() => pdfDownload(data.id)}>Submit</button>
                                                    {data.certificate.length ? <button onClick={() => downloadFile(data.certificate)} className='btn btn-success pdfBtn'>{data.certificate}</button> : ""}
                                                </div>
                                            }
                                        </td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OrderList
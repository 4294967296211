import React from "react";
import "./Card.css";
import { Link } from "react-router-dom";
import { API } from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Card = (props) => {
    return (
        <>
            <article>
                <div className="article-wrapper">
                    <figure>
                        <LazyLoadImage src={`${API}/${props.data.course_image}`} alt="" />
                    </figure>
                    <div className="article-body">
                        <Link to={`/details/${props.data.course_id}`}>{props.data.course_name}</Link>
                        <div className="d-flex justify-content-between">
                            <p>
                                <b>কোর্স ফি</b>: {props.data.course_price}
                            </p>
                            <p>
                                <b>সময়</b>: {props.data.course_length}
                            </p>
                        </div>
                    </div>
                </div>
            </article>
        </>
    );
};

export default Card;

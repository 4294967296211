import { API } from "../config";
import axios from 'axios';
// create course
export const courseOrder = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/courseOrder`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
// order list
export const allOrder = async () => {
    try {
        const response = await axios.get(`${API}/orderList`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// order complete update
export const updateOrderStatus = async (orderId, body) => {
    try {
        const response = await axios.put(`${API}/updateOrder/${orderId}`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// active order list
export const activeOrder = async (mobile) => {
    try {
        const response = await axios.get(`${API}/activeOrder/${mobile}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// refer list
export const referralList = async () => {
    try {
        const response = await axios.get(`${API}/referralList`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
// check order
export const courseRunning = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/courseRunning`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// delete order
export const deleteOrders = async (id) => {
    try {
        const response = await axios.delete(`${API}/deleteOrder/${id}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
// payment type update
export const studentPayment = async (orderId, body) => {
    try {
        const response = await axios.put(`${API}/paymentType/${orderId}`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
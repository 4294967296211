import React from "react";
import { Link } from "react-router-dom";
import { API } from "../../config";

const ActiveCard = (props) => {
    return (
        <>
            <article>
                <div className="article-wrapper">
                    <figure>
                        <img src={`${API}/${props.data.image}`} alt="" />
                    </figure>
                    <div className="article-body">
                    {props.data.certificate ? <span className="complete">Complete</span> : <>
                    {props.data.complete === "1" ? <span className="running">Running</span> : <span className="pending">Pending</span>}
                    </>}
                    
                        <Link to={`/details/${props.data.course_id}`}>{props.data.name}</Link>
                        <div className="d-flex justify-content-between">
                            <p>
                                <b>কোর্স ফি</b>: {props.data.price}
                            </p>
                            <p>
                                <b>সময়</b>: {props.data.length}
                            </p>
                        </div>
                    </div>
                </div>
            </article>
        </>
    )
}

export default ActiveCard
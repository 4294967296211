import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { courseUpdate, singleCourse } from '../../api/course';
import { API } from "../../config";
import 'quill/dist/quill.snow.css';
import ReactQuill  from 'react-quill';

const EditCourse = (props) => {
    const { id } = useParams();
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);
    const [content, setContent] = useState("");
    const [description, setDescription] = useState("");
    const [courseImage, setCourseImage] = useState("");
    const [length, setLength] = useState("");
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [imageKey, setImageKey] = useState("");
    function handleChange(e) {
        setImageKey(e.target.files[0])
    }

    useEffect(() => {
        
        singleCourse(id).then((data) => {
            setDescription(data.data.course_description);
            setCourseImage(data.data.course_image);
            setLength(data.data.course_length);
            setName(data.data.course_name);
            setPrice(data.data.course_price);
            setContent(data.data.course_description)
        })
    }, []);

    const update = () => {
        let course = {
            "update_image": imageKey,
            "course_name": name,
            "course_price": price,
            "course_length": length,
            "course_description": content,
        }
        courseUpdate(id, course).then((data) => {
            props.setEditShow(false)
        })
    }


    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "align", "size",
    ];

    const handleProcedureContentChange = (content) => {
         setContent(content)
    };


    return (
        <div>
            <Modal show={props.editShow} onHide={props.handleEditClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Course Information</Modal.Title>
                </Modal.Header>
                <div className='addCourse'>
                
                    <div className='inputField'>
                        <div className='text-center mb-2 mt-1'>
                            <img className='prevImg' src={`${API}/${courseImage}`} alt="profile" />
                        </div>
                        <label>Course Image</label>

                        <input type='file' onChange={handleChange} placeholder='Enter your course name' className='form-control' required />
                    </div>
                    <div className='inputField'>
                        <label>Course Name</label>
                        <input type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter your course name' className='form-control' required />
                    </div>
                    <div className='inputField'>
                        <label>Course Price</label>
                        <input type='number' value={price} onChange={(e) => setPrice(e.target.value)} placeholder='Enter your course price' className='form-control' required />
                    </div>
                    <div className='inputField'>
                        <label>Course Length</label>
                        <input type='text' value={length} onChange={(e) => setLength(e.target.value)} placeholder='Enter your course length' className='form-control' required />
                    </div>
                    <div className='inputField'>
                        <label>Course Description</label>
                        <div style={{ display: "grid", justifyContent: "center" }}>
                        <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        placeholder="write your content ...."
                        onChange={handleProcedureContentChange}
                        style={{ height: "220px" }}
                        value={content}
                    >
                    </ReactQuill>
                    </div>
                    {/**   <textarea type='text' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Enter your course description' className='form-control' required /> */}
                    </div>
                    <div className='inputField text-center'>
                        <button type='submit' onClick={update}>Update Course</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default EditCourse
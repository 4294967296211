import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import "./AddCourse.css";
import { v4 as uuidv4 } from 'uuid';
import { courseAdd } from '../../api/course';
import 'quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const AddCourse = (props) => {
    const form = useRef(null);
    const { handleSubmit, register, setValue } = useForm();
    const [content, setContent] = useState("");
    let onSubmit = () => {
        const courseInfo = new FormData(form.current);
        const course_id = uuidv4();
        courseInfo.append('course_id', course_id);
        courseInfo.append('course_description', content);

        courseAdd(courseInfo).then((data) => {
            props.setShow(false);
        })
    }


    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "align", "size",
    ];

    const handleProcedureContentChange = (content) => {
        console.log("content---->", content);
        setContent(content)
    };

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Course Information</Modal.Title>
                </Modal.Header>
                <div className='addCourse'>
                    <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                        <div className='inputField'>
                            <label>Course Image</label>
                            <input type='file' {...register("course_image")} placeholder='Enter your course name' className='form-control' required />
                        </div>
                        <div className='inputField'>
                            <label>Course Name</label>
                            <input type='text' {...register("course_name")} placeholder='Enter your course name' className='form-control' required />
                        </div>
                        <div className='inputField'>
                            <label>Course Price</label>
                            <input type='number' {...register("course_price")} placeholder='Enter your course price' className='form-control' required />
                        </div>
                        <div className='inputField'>
                            <label>Course Length</label>
                            <input type='text' {...register("course_length")} placeholder='Enter your course length' className='form-control' required />
                        </div>
                        <div className='inputField'>
                            <label>Course Description</label>
                            <div style={{ display: "grid", justifyContent: "center" }}>
                                <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    placeholder="write your content ...."
                                    onChange={handleProcedureContentChange}
                                    style={{ height: "220px" }}
                                >
                                </ReactQuill>
                            </div>
                          {/*   <textarea type='text' {...register("course_description")} placeholder='Enter your course description' className='form-control' required /> */}
                        </div>
                        <div className='inputField text-center'>
                            <button type='submit'>Add Course</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}

export default AddCourse
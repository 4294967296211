import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { userProfile, userUpdate } from '../../api/user';
import { API } from "../../config";

const EditProfile = (props) => {
    const savedUserProfile = localStorage.getItem("niomit");
    const user = JSON.parse(savedUserProfile);
    const [userInfo, setUserInfo] = useState({});
    const [name, setName] = useState("");
    const [imageKey, setImageKey] = useState("");
    const [file, setFile] = useState();
    const [userImage, setUserImage] = useState("");
    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
        setImageKey(e.target.files[0])
    }
    useEffect(() => {
        userProfile(user.mobile).then((data) => {
            setUserInfo(data);
            setUserImage(data.image)
            setName(data.user_name);
        });
    }, []);

    const profileApi = () => {
        let userInfo = {
            "user_name": name,
            "image": imageKey,
        }
        userUpdate(user.mobile, userInfo).then((data) => {
            props.setProfileShow(false)
        });
    }
    return (
        <div>
            <Modal show={props.profileShow} onHide={props.handleProfileClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Update Information</Modal.Title>
                </Modal.Header>
                <div className='addCourse'>

                    <div className='addCourse'>
                        <div className='inputField'>
                            <div className='text-center mb-2 mt-1 imgFile'>
                                {file && file.length > 0 ? <img src={file} alt="shop" />
                                    : <img className='prevImg' src={`${API}/${userImage}`} alt="profile" />
                                }
                            </div>
                            <div className='inputField text-center'>
                                <input type="file" name="file" id="file" onChange={handleChange} className="inputFile" />
                                <label for="file">Choose a file</label>
                            </div>

                        </div>
                        <div className='inputField'>
                            <input type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter your course name' className='form-control' />
                        </div>
                    </div>

                    <div className="loginFooter">
                        <button onClick={profileApi}>Continue</button>
                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default EditProfile
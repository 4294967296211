import { API } from "../config";
import axios from 'axios';
// admin login
export const loginAdmin = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/adminLogin`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// admin login
export const addBranch = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/branch`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// get branch list
export const getBranch = async () => {
    try {
        const response = await axios.get(`${API}/getBranch`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};



export const updatePdf = async (id, body = {}) => {
    try {
        const response = await axios.put(`${API}/updateCertificate/${id}`, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


// admin refer info
export const referAmountAdd = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/referAmount`, body);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
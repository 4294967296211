import { API } from "../config";
import axios from 'axios';
// signup
export const registerUser = async (body = {}) => {
  try {
    const response = await axios.post(`${API}/userCreate`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
// login
export const loginUser = async (mobile, password) => {
  try {
    const response = await axios.post(`${API}/login`, {
      mobile: mobile,
      password: password,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
// mobile otp
export const mobileOtp = async (mobile, otp) => {
  const url = "https://api.sms.net.bd/sendsms";

  const data = new FormData();
  data.append("api_key", "QRa72z0YlJt58U7gxw7WgAXNdyYw0PpeCTrsnT0l");
  data.append("msg", `Your verification code is ${otp} "Area phonebook"`);
  data.append("to", mobile);

  axios
    .post(url, data)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};
// number check
export const numberCheck = async (mobile) => {
  try {
    const response = await axios.post(`${API}/mobileCheck`, {
      mobileNumber: mobile,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// user info
export const userInfo = async (userMobile) => {
  try {
    const response = await axios.post(`${API}/userDashboard`, {
      userMobile: userMobile,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
// user password update
export const passwordUpdate = async (mobile,newPassword) => {
  try {
    const response = await axios.put(`${API}/user/${mobile}`, {
      newPassword: newPassword
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
// user profile info
export const userProfile = async (mobile) => {
  try {
    const response = await axios.get(`${API}/profile/${mobile}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// user refer list
export const referStudent = async (mobile) => {
  try {
    const response = await axios.get(`${API}/studentRefer/${mobile}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// user refer list
export const getReferList = async () => {
  try {
    const response = await axios.get(`${API}/referList`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
// refer amount
export const referAmount = async (refer_id,amount) => {
  try {
    const response = await axios.put(`${API}/referAmountMinus/${refer_id}`, {
      amount: amount
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
// user update
export const userUpdate = async (mobile,body = {}) => {
  try {
      const response = await axios.put(`${API}/updateProfile/${mobile}`, body,{headers: {
          'Content-Type': 'multipart/form-data',
        },});
      return response.data;
  } catch (error) {
      return error.response.data;
  }
};

// profile  refer list
export const getProfileReferList = async (id) => {
  try {
    const response = await axios.get(`${API}/referListShow/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
// profile refer amount
export const userAmount = async (id) => {
  try {
    const response = await axios.get(`${API}/profileReferAmount/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
// profile refer amount list
export const userAmountList = async (id) => {
  try {
    const response = await axios.get(`${API}/profileReferList/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
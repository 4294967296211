import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { adminLogout } from '../../api/auth';
import { useNavigate } from "react-router-dom";
import { NavDropdown } from 'react-bootstrap';

const AdminHeader = () => {
    const navigate = useNavigate();
    const logoutBtn = () => {
        adminLogout();
        return navigate(`/login`);
    };
    return (
        <Navbar collapseOnSelect expand="lg">
            <Container>
                <Nav className="d-flex justify-content-between w-100 align-items-center flex-row">
                    <Nav.Link className='homeIcon' href="/dashboard"><FontAwesomeIcon icon={faHome} /></Nav.Link>
                    <Nav.Link href="/dashboard"><img src="https://niomit.einfo.website/asset/img/logo/logo.png" alt="logo" width="120px" /></Nav.Link>
                    <div className='adminNavbar'>
                        <NavDropdown className='userIcon' id="nav-dropdown" title={<FontAwesomeIcon icon={faUser} />}>
                            <NavDropdown.Item href="/dashboard">Course</NavDropdown.Item>
                            <NavDropdown.Item href="/order-list">Student</NavDropdown.Item>
                            <NavDropdown.Item href="/refer-list">Refer</NavDropdown.Item>
                            <NavDropdown.Item href="/other">Other</NavDropdown.Item>
                            <NavDropdown.Item onClick={logoutBtn} className='logout'>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </Nav>
            </Container>
        </Navbar>
    )
}

export default AdminHeader
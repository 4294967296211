import React, { useEffect, useState } from 'react'
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import Header from '../../Component/Header/Header';
import { getProfileReferList, referStudent, userAmount, userAmountList, userProfile } from '../../api/user';
import "./Profile.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { API } from "../../config";
import EditProfile from './EditProfile';
import moment from 'moment';

const Profile = () => {
    const savedUserProfile = localStorage.getItem("niomit");
    const user = JSON.parse(savedUserProfile);
    const [userInfo, setUserInfo] = useState({});
    const [userTotal, setUserTotal] = useState("");
    const [userRefer, setUserRefer] = useState([]);
    console.log('userRefer', userRefer);
    const [paymentList, setPaymentList] = useState([]);
    const [profileShow, setProfileShow] = useState(false);
    const handleProfileClose = () => setProfileShow(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        userProfile(user.mobile).then((data) => {
            setUserInfo(data)
        })
        userAmount(user.mobile).then((data) => {
            if (data.data) {
                setUserTotal(data.data.amount)
            }

        })
        getProfileReferList(user.mobile).then((data) => {
            setUserRefer(data)
        })
    }, [profileShow]);

    // editProfile
    const editProfile = () => {
        setProfileShow(true);
    }
    const amountList = (refer_id) => {
        userAmountList(refer_id).then((data) => {
            if (data.length) {
                setPaymentList(data);
                setShow(true);
            }
            else {
                setShow(false);
            }
        })
    }

    return (
        <>
            <Header />
            <section className='homePage'>
                <Container>
                    <Row className={userRefer ? "d-flex justify-content-center" : ""}>
                        <Col md={3}  className='mt-5'>
                            <div className="card" style={{ borderRadius: '26px' }}>
                                <div className="image-content">
                                    <span className="overlay"></span>
                                    <div className="card-image">
                                        <LazyLoadImage src={`${API}/${userInfo.image}`} alt="user" className="card-img" />
                                    </div>
                                </div>
                                <div className="card-content">
                                    <h2 className="name">{userInfo.user_name}</h2>
                                    <p className="description">{userInfo.mobile}</p>
                                    <p className='dueDate' onClick={() => amountList(userInfo.mobile)}>Earning: {userTotal && userTotal ? userTotal : "0"}</p>
                                    <button className="button" onClick={editProfile}>Edit</button>
                                </div>
                            </div>
                        </Col>

                        {userRefer && userRefer.length ? <>
                            <Col md={9} className='mt-5'>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Number</th>
                                            <th>Course</th>
                                            <th>Branch</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userRefer.map((data, index) => <tr key={`order` + index}>
                                            <td>{index + 1}</td>
                                            <td><img src={`${API}/${data.image}`} alt={data && data.course} width="50px" height="40px" /></td>
                                            <td>{data.user_name}</td>
                                            <td>{data.mobile}</td>
                                            <td>{data.course}</td>
                                            <td>{data.branch}</td>
                                            <td>{data.refer_discount}</td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </Col>
                        </> : <></>}
                    </Row>
                </Container>
            </section>


            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Payment List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Transaction id</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentList && paymentList.map((data, i) => <tr>
                                <td>{i + 1}</td>
                                <td>{moment(data.created_at).format('DD-MM-YYYY')}</td>
                                <td>{data.amount}</td>
                                <td>{data.transaction}</td>
                            </tr>)}

                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>

            <EditProfile profileShow={profileShow} setProfileShow={setProfileShow} handleProfileClose={handleProfileClose} />
        </>
    )
}

export default Profile
import React, { useState, useEffect } from 'react';
import AdminHeader from '../../../Component/AdminHeader/AdminHeader';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import { allCourse, branchDelete, discountAdd } from '../../../api/course';
import "./Other.css";
import { v4 as uuidv4 } from 'uuid';
import { addBranch, getBranch } from '../../../api/admin';

const Other = () => {
    const [courseList, setCourseList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [amount, setAmount] = useState("");
    const [refer, setRefer] = useState("");
    const [count, setCount] = useState(0);
    const [courseId, setCourseId] = useState("");
    const [branchName, setBranchName] = useState("");
    const [paymentDiscount, setPaymentDiscount] = useState("");
    

    const handleSelectChange = (e) => {
        setCourseId(e.target.value);
    };

    useEffect(() => {
        allCourse().then((data) => {
            if (data.statusCode !== 200) {

            }
            else {
                setCourseList(data.data);
            }
        });
        getBranch().then((data) => {
            setBranchList(data.data)
        });
    }, [count]);

    // course discount add
    const discount = () => {
        discountAdd(courseId, amount, refer,paymentDiscount).then((data) => {
            setCount(count + 1);
        });
    }

    const branchAdd = () => {
        const branch_id = uuidv4();
        let branch = {
            "id": branch_id,
            "branch": branchName
        }
        addBranch(branch).then((data) => {
            setCount(count + 1);
        });
    }

    const branchDEl = (branchId) => {
        branchDelete(branchId).then((data) => {
            setCount(count + 1);
        });
    }
    return (
        <div>
            <AdminHeader />
            <section className='orderSection'>
                <Container>
                    <Row>
                        <Col md={6} className='orderHeading'>
                            <h1>Course discount add</h1>
                            <div className='courseDiscount'>
                                <Form.Select aria-label="Default select example" value={courseId} onChange={handleSelectChange}>
                                <option value="">Select Course</option>    
                                {courseList && courseList.map((data) =>
                                        <option value={data.course_id}>{data.course_name}</option>
                                    )}
                                </Form.Select>
                                <input type="number" placeholder='Amount' className='form-control' onChange={(e) => setAmount(e.target.value)} />
                                <input type="number" placeholder='Amount' className='form-control' onChange={(e) => setRefer(e.target.value)} />
                                <input type="number" placeholder='Amount' className='form-control' onChange={(e) => setPaymentDiscount(e.target.value)} />
                                <button className='btn btn-info' onClick={discount}>Save</button>
                            </div>
                            <div className='discountList'>
                                <h2>Discount course list</h2>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Course name</th>
                                            <th>Discount amount</th>
                                            <th>Refer amount</th>
                                            <th>Payment Discount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {courseList && courseList.map((data, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{data.course_name}</td>
                                                <td>{data.course_discount}</td>
                                                <td>{data.refer_discount}</td>
                                                <td>{data.paymentDiscount}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='branchName'>
                                <h1>Branch name</h1>
                                <div className='d-flex'>
                                    <input type="text" placeholder='Branch name' onChange={(e) => setBranchName(e.target.value)} className='form-control' />
                                    <button onClick={branchAdd} className='btn btn-info'>Save</button>
                                </div>
                            </div>
                            <div className='branchList mt-3'>
                                <h1>Branch list</h1>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Branch</th>
                                            <th>Branch</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {branchList && branchList.map((data, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{data.branch}</td>
                                                <td><button className='btn btn-danger' onClick={()=>branchDEl(data.id)}>Delete</button></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Other
import React, { useState, useEffect } from 'react';
import Card from '../../Component/Card/Card';
import { Container, Row, Col } from 'react-bootstrap';
import "./Home.css";
import Layout from '../../Component/Layout/Layout';
import { allCourse } from '../../api/course';

const Home = () => {
    const [courseList, setCourseList] = useState([]);

    useEffect(() => {
        allCourse().then((data) => {
            if (data.statusCode !== 200) {

            }
            else {
                setCourseList(data.data);
            }
        });
    }, [])


    return (
        <Layout>
            <section className='homePage'>
                <Container>
                    <Row>
                        {courseList && courseList.map((data, i) =>
                            <Col md={3} key={`card` + i}>
                                <Card data={data} />
                            </Col>
                        )}
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export default Home
import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import AdminHeader from '../../../Component/AdminHeader/AdminHeader';
import { getReferList, referAmount, userAmountList } from '../../../api/user';
import { API } from "../../../config";
import { referAmountAdd } from '../../../api/admin';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const UserList = () => {
  const [orderList, setOrderList] = useState([]);
  const [amount, setAmount] = useState("");
  const [transaction, setTransaction] = useState("");
  const [count, setCount] = useState(0);
  const [paymentList, setPaymentList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getReferList().then((data) => {
      if (data.statusCode !== 200) {

      }
      else {
        setOrderList(data.data);
      }
    })
  }, [count]);

  // refer amount update
  const updateRefer = (refer_id) => {
    let referInfo = {
      "id": uuidv4(),
      "mobile": refer_id,
      "amount": amount,
      "transaction": transaction
    }
    referAmount(refer_id, amount).then((data) => {
      setCount(count + 1)
    })
    referAmountAdd(referInfo).then((data) => {
      setCount(count + 1)
      setTransaction("")
      setAmount("")
    })
  }


  const amountList = (refer_id) => {
    userAmountList(refer_id).then((data) => {
      if (data.length) {
        setPaymentList(data);
        setShow(true);
      }
      else {
        setShow(false);
      }

    })
  }

  return (
    <div>
      <AdminHeader />
      <section className='orderSection'>
        <Container>
          <Row>
            <Col md={12} className='orderHeading'>
              <h1>Refer list</h1>
            </Col>
            <Col md={12}>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Total</th>
                    <th>Amount</th>
                    <th style={{ width: '250px' }}>Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {orderList.map((data, index) => <tr key={`refer` + index}>
                    <td>{orderList.length - index}</td>
                    <td><img src={`${API}/${data.image}`} alt={data && data.course} width="50px" height="40px" /></td>
                    <td>{data.name}</td>
                    <td>{data.refer_id}</td>
                    <td onClick={() => amountList(data.refer_id)}>{data.total}</td>
                    <td>{data.amount}</td>
                    <td className='d-flex'>
                      <input type="number" style={{ width: "180px" }} placeholder='Amount' className='form-control' onChange={(e) => setAmount(e.target.value)} />
                      <input type="text" style={{ width: "180px" }} placeholder='Transaction' className='form-control' onChange={(e) => setTransaction(e.target.value)} />
                      <button className='btn btn-success' onClick={() => updateRefer(data.refer_id)}>Payment</button> </td>
                  </tr>)}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Payment List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Transaction id</th>
                </tr>
              </thead>
              <tbody>
                {paymentList && paymentList.map((data, i) => <tr>
                  <td>{i + 1}</td>
                  <td>{moment(data.created_at).format('DD-MM-YYYY')}</td>
                  <td>{data.amount}</td>
                  <td>{data.transaction}</td>
                </tr>)}

              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

      </section>
    </div>
  )
}

export default UserList
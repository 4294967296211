//save jwt to local storage
export const authenticate = (data, next) => {
    if (typeof window !== undefined) {
        localStorage.setItem("niomit", JSON.stringify(data));
        next();
    }
};
// is authenticte
export const isAuthenticate = () => {
    if (typeof window == undefined) {
        return false;
    }
    if (localStorage.getItem("niomit")) {
        return JSON.parse(localStorage.getItem("niomit") || '{}');
    } else {
        return false;
    }
};
export const isAdminAuthenticate = () => {
    if (typeof window == undefined) {
        return false;
    }
    if (localStorage.getItem("niomadmin")) {
        return JSON.parse(localStorage.getItem("niomadmin") || '{}');
    } else {
        return false;
    }
};
// logout
export const logout = async () => {
    localStorage.removeItem("niomit");
};

// logout
export const adminLogout = async () => {
    localStorage.removeItem("niomadmin");
};
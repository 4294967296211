import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./Details.css";
import ReferModal from '../../Component/ReferModal/ReferModal';
import Layout from '../../Component/Layout/Layout';
import { useParams } from 'react-router-dom';
import { courseDelete, singleCourse } from '../../api/course';
import { API } from "../../config";
import { courseRunning } from '../../api/order';
import EditCourse from '../../Component/AddCourse/EditCourse';
import { useNavigate } from "react-router-dom";

const Details = () => {
    const navigate = useNavigate();
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);
    const adminRole = localStorage.getItem("niomadmin");
    const adminProfile = JSON.parse(adminRole);
    const [courseInfo, setCourseInfo] = useState({});
    const [show, setShow] = useState(false);
    const [running, setRunning] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [editShow, setEditShow] = useState(false);
    const handleEditClose = () => setEditShow(false);
    const handleEditShow = () => setEditShow(true);

    const { id } = useParams();
    useEffect(() => {
        let course = {
            "course_id": id,
            "mobile": userProfile && userProfile.mobile
        }
        singleCourse(id).then((data) => {
            setCourseInfo(data.data);
        })
        courseRunning(course).then((data) => {
            setRunning(data.course)
        })
    }, [editShow]);

    const downloadFile = async (certificate) => {
        const imageUrl = `${API}/${certificate}`; // Replace with the actual image URL

        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Create an anchor element with a download attribute
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = 'niomit.jpg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    // edit
    const editCourse = () => {
        setEditShow(true);
    }

    
     // delete course
     const deleteCourse = () => {
        courseDelete(id).then((data) => {
           return navigate("/dashboard");
        })
    }
  
    return (
        <Layout>
            <section className='detailsPage'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col md={7}>
                            <div className='detailsSection'>
                            <h1>{courseInfo && courseInfo.course_name}</h1>
                            <div className='d-flex mb-3'>
                            <h2>কোর্স ফি: {courseInfo && courseInfo.course_price} </h2>
                            <h3> সময়: {courseInfo && courseInfo.course_length}</h3>
                            </div>
                                <img src={`${API}/${courseInfo.course_image}`} alt={courseInfo && courseInfo.course_name} />
                               
                                <div dangerouslySetInnerHTML={{__html: `${courseInfo && courseInfo.course_description}`}} />
                                <p>{courseInfo && courseInfo.certificate}</p>
                                <div className='enrollBtn'>
                                
                                    {adminProfile && adminProfile.role === "admin" ? <>
                                    <button onClick={editCourse}>Edit</button>
                                    <button onClick={deleteCourse}>Delete</button>
                                    </> :
                                    <>
                                    {running && running.certificate ? <button onClick={() => downloadFile(running.certificate)}>Download Certificate</button> : <>
                                    {running && running.complete === "1" ? <button>Course Running</button> :
                                        <button onClick={handleShow}>Enroll Now</button>
                                    }
                                </>}
                                    </>
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <ReferModal show={show} setShow={setShow} handleClose={handleClose} courseInfo={courseInfo} />
            <EditCourse editShow={editShow} setEditShow={setEditShow} handleEditClose={handleEditClose} courseInfo={courseInfo} />
        </Layout>
    )
}

export default Details
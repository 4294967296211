import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from '../../../Component/Card/Card';
import AddCourse from '../../../Component/AddCourse/AddCourse';
import { allCourse } from '../../../api/course';
import "./Dashboard.css";
import AdminHeader from '../../../Component/AdminHeader/AdminHeader';

const Dashboard = () => {
    const [courseList, setCourseList] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        allCourse().then((data) => {
            if (data.statusCode !== 200) {

            }
            else {
                setCourseList(data.data);
            }
        })
    }, [show])

    return (
        <>
            <AdminHeader />
            <section className='dashboardPage'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className='courseAddBtn'>
                                <button onClick={handleShow}>Add Course</button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {courseList && courseList.map((data, i) =>
                            <Col md={4} key={`card` + i}>
                                <Card data={data} />
                            </Col>
                        )}
                    </Row>
                </Container>
            </section>
            <AddCourse show={show} setShow={setShow} handleClose={handleClose} />
        </>
    )
}

export default Dashboard
import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import "./ReferModal.css";
import { searchTerm } from '../../api/course';
import { courseOrder } from '../../api/order';
import { v4 as uuidv4 } from 'uuid';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { API } from "../../config";
import { getBranch } from '../../api/admin';
import { useNavigate } from "react-router-dom";

const ReferModal = (props) => {
    const navigate = useNavigate();
    const [userList, setUserList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [transactionIdError, setTransactionIdError] = useState("");
    const [branchError, setBranchError] = useState("");
    const [referName, setReferName] = useState("");
    const [referNameError, setReferNameError] = useState("");
    const [paymentError, setPaymentError] = useState("");
    const [referId, setReferId] = useState("");
    const [transaction, setTransaction] = useState(false);
    const [branchId, setBranchId] = useState("");
    const [paymentValue, setPaymentValue] = useState("");

    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);

    useEffect(() => {
        searchTerm(searchValue).then((data) => {
            setUserList(data.results);
        });
    }, [searchValue]);
    useEffect(() => {
        getBranch().then((data) => {
            setBranchList(data.data);
            // setBranchId(data.data[0].id)
        });
    }, []);

    const referAdd = (referName, mobile) => {
        setReferNameError("");
        setReferName(referName);
        setReferId(mobile);
    }

    const referBranch = () => {
        if (branchId.length === 0) {
            setBranchError("Please choose a branch");
        }
        else {
            setBranchError("");
        }
        if (paymentValue.length === 0) {
            setPaymentError("Please choose a payment option");
        }
        else {
            setPaymentError("");
        }
        if (referName.length === 0) {
            setReferNameError("Please enter a refer name");
        }
        else {
            setReferNameError("");

        }
        
        if (referName.length > 0 && branchId.length > 0 && paymentValue.length > 0) {
            setTransaction(true);
        }
    }
    const orderComplete = () => {
        if (transactionId.length === 0) {
            setTransactionIdError("Please enter your transaction id");
        }
        else {
            setTransactionIdError("");
        }

        let order = {
            "id": uuidv4(),
            "name": props.courseInfo.course_name,
            "mobile": userProfile.mobile,
            "image": props.courseInfo.course_image,
            "course": props.courseInfo.course_name,
            "course_id": props.courseInfo.course_id,
            "refer_name": referName,
            "refer_id": referId,
            "refer_discount": props.courseInfo.refer_discount,
            "price": props.courseInfo.course_price,
            "length": props.courseInfo.course_length,
            "transaction": transactionId,
            "paymentType": paymentValue,
            "total": 1,
        }
        for (let index = 0; index < branchList.length; index++) {
            if (branchList[index].id === branchId) {
                order.branch = branchList[index].branch;
                break;
            }
        }

        if (transactionId.length > 0 && branchId.length > 0 && paymentValue.length > 0) {
            courseOrder(order).then((data) => {
                props.setShow(false);
                setTransaction(false);
                return navigate("/my-course");
            });
        }


    }

    function formatMobileNumber(mobile) {
        if (mobile && typeof mobile === 'string' && mobile.length === 11) {
            const firstFourDigits = mobile.slice(0, 4);
            const lastDigit = mobile.slice(-3);
            const middleDigits = '*****';
            const formattedMobile = `${firstFourDigits}${middleDigits}${lastDigit}`;
            return formattedMobile;
        } else {
            return 'Invalid number';
        }
    }

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Course Booking</Modal.Title>
                </Modal.Header>
                {transaction ? <div className='referAdd'>
                    {paymentValue === "full" ? <h1>Fee: {parseInt(props.courseInfo.course_price) - (parseInt(props.courseInfo.course_discount) +  parseInt(props.courseInfo.paymentDiscount))} taka</h1>
                        : <h1>Fee: {parseInt(props.courseInfo.course_price) - parseInt(props.courseInfo.course_discount)} taka</h1>}
                    <h1>Bkash 012345678901</h1>
                    <input placeholder='Transaction id' className='form-control' onChange={(e) => setTransactionId(e.target.value)} />
                    {transactionIdError && transactionIdError ? <h6 className='errorMsg'>{transactionIdError}</h6> : ""}
                    {userProfile && userProfile ?
                        <div className='d-flex justify-content-between'>
                            <button className='continue' onClick={() => setTransaction(false)}>Back</button>
                            <button className='continue' onClick={() => orderComplete()}>Continue</button>
                        </div> :
                        <h4 className='loginWarning'>Please login first</h4>
                    }

                </div>
                    : <div className='referAdd'>

                        <Form.Select aria-label="Default select example" value={branchId} onChange={(e) => setBranchId(e.target.value)}>
                            <option value="">Select branch</option>
                            {branchList && branchList.map((data) =>
                                <option value={data.id}>{data.branch}</option>
                            )}
                        </Form.Select>
                        {branchError && branchError ? <h6 className='errorMsg'>{branchError}</h6> : ""}
                        <Form.Select aria-label="Default select example" className='mt-3' value={paymentValue} onChange={(e) => setPaymentValue(e.target.value)}>
                            <option value="">Select Payment Option</option>
                            <option value="half">Half Payment</option>
                            <option value="full">Full Payment</option>
                        </Form.Select>
                        {paymentError && paymentError ? <h6 className='errorMsg'>{paymentError}</h6> : ""}
                     
                        <input placeholder='Refer name' className='form-control mt-3' onChange={(e) => setSearchValue(e.target.value)} />
                        {referNameError && referNameError ? <h6 className='errorMsg'>{referNameError}</h6> : ""}
                        <h1>{referName}</h1>
                        <div className='referBtn'>
                            {userList && userList.map((data) =>
                                <button onClick={() => referAdd(data.user_name, data.mobile)} className='d-flex justify-content-between align-items-center'>
                                    <img src={`${API}/${data.image}`} alt={data.image} width="40px" height="40px" />
                                    <p>{data.user_name}</p>
                                    <p>{formatMobileNumber(data.mobile)}</p>
                                </button>
                            )}
                        </div>
                        <button className='continue' onClick={referBranch}>Continue</button>
                    </div>}
            </Modal>
        </div>
    )
}

export default ReferModal
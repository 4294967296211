import React, { useEffect, useState } from 'react'
import { activeOrder } from '../../api/order';
import { Col, Container, Row } from 'react-bootstrap';
import ActiveCard from '../../Component/ActiveCard/ActiveCard';
import Header from '../../Component/Header/Header';
import "./MyCourse.css";
import AddCourse from '../../Component/AddCourse/AddCourse';

const MyCourse = () => {
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);
    const [orderList, setOrderList] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        activeOrder(userProfile.mobile).then((data) => {
            if (data.statusCode !== 200) {

            }
            else {
                setOrderList(data.data);
            }
        })
    }, [])
    return (
        <div>
            <Header />
            <section className='myCourseSection'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <button onClick={handleShow}>Add Course</button>
                        </Col>
                    </Row>
                    <Row>
                        {orderList && orderList.length ?
                            orderList.map((data, i) =>
                                <Col md={3} key={`card` + i}>
                                    <ActiveCard data={data} />
                                </Col>
                            )
                            : <h1 className='text-center text-danger mt-5'>No Course you added</h1>
                        }
                    </Row>
                </Container>
            </section>
            <AddCourse show={show} setShow={setShow} handleClose={handleClose} />
        </div>
    )
}

export default MyCourse
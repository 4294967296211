import React from 'react';
import { BrowserRouter, Route, Routes, Router } from "react-router-dom";
import Details from './Page/Details/Details';
import Home from './Page/Home/Home';
import Dashboard from './Page/Admin/Dashboard/Dashboard';
import OrderList from './Page/Admin/OrderList/OrderList';
import Profile from './Page/Profile/Profile';
import UserList from './Page/Admin/UserList/UserList';
import AdminGuard from './Component/AdminGuard/AdminGuard';
import Login from './Page/Admin/Login/Login';
import MyCourse from './Page/MyCourse/MyCourse';
import Other from './Page/Admin/Other/Other';

const Routing = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    {/* admin guard  */}
                    <Route element={<AdminGuard />}>
                        <Route path="/dashboard" element={<Dashboard />}></Route>
                        <Route path="/order-list" element={<OrderList />}></Route>
                        <Route path="/refer-list" element={<UserList />}></Route>
                        <Route path="/other" element={<Other />}></Route>
                    </Route>
                    {/* home */}
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/details/:id" element={<Details />}></Route>
                    <Route path="/my-course" element={<MyCourse />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/profile" element={<Profile />}></Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Routing